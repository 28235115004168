import React from "react";
import { useNavigate } from "react-router-dom";

const AdminNavbar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("auth_token");  // or sessionStorage.removeItem('auth_token');

        navigate("/");
    };

    return (
        <nav className="bg-[#191919] px-8 py-4 text-white flex justify-between items-center">
            <div className="flex space-x-4">
                <a href="/dashboard" className="hover:text-orange-500">DASHBOARD</a>
                <a href="/newsletter" className="hover:text-orange-500">NEWSLETTER</a>
            </div>
            <button
                onClick={handleLogout}
                className="text-red-600 hover:text-red-700 font-bold px-4 py-2 rounded-full"
            >
                LOG OUT
            </button>
        </nav>
    );
};

export default AdminNavbar;
