import React, { useState } from "react";

const PaginatedTable = ({ users, onRowClick }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const itemsPerPage = 25;

    // Filter users based on the search query
    const filteredUsers = users.filter((user) => {
        const search = searchQuery.toLowerCase();
        return (
            user.name.toLowerCase().includes(search) ||
            user.email.toLowerCase().includes(search) ||
            user.phone.toLowerCase().includes(search) ||
            user.regist_id?.toLowerCase().includes(search)
        );
    });

    // Calculate the total number of pages based on filtered users
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the filtered users data to display only the current page
    const currentUsers = filteredUsers.slice(startIndex, endIndex);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div>
            {/* Search input */}
            <div className="mb-4 flex justify-between items-center text-white">
                <p>Total Registrations: <span className="font-bold">{users.length}</span></p>

                <input
                    type="text"
                    placeholder="Search by name, email, phone, or registration ID"
                    className="px-4 py-2 rounded-full text-gray-900 min-w-96"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="bg-[#F8FAFA] shadow py-8 px-4 rounded-lg text-black">

                <table className="min-w-full table-auto">
                    <thead>
                        <tr className="border-b text-gray-500">
                            <th className="px-4 py-2 text-start font-light">NAME</th>
                            <th className="px-4 py-2 text-start font-light">EMAIL</th>
                            <th className="px-4 py-2 text-start font-light">PHONE</th>
                            <th className="px-4 py-2 text-start font-light">COMMUNITY</th>
                            <th className="px-4 py-2 text-start font-light">REGISTERED ON</th>
                            <th className="px-4 py-2 text-start font-light">REGISTRATION ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.map((user) => (
                            <tr
                                onClick={() => onRowClick(user.id)}
                                key={user.id}
                                className="border-b hover:bg-[#f06512] hover:text-white hover:cursor-pointer"
                            >
                                <td className="p-4">{user.name}</td>
                                <td className="px-4 py-2 ">{user.email}</td>
                                <td className="px-4 py-2 ">{user.phone}</td>
                                <td className="px-4 py-2 ">{user.community}</td>
                                <td className="px-4 py-2 ">
                                    {new Date(user.created_at).toLocaleDateString("en-GB", {
                                        day: "numeric",
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </td>
                                <td className="px-4 py-2 ">{user.regist_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Showing X of Y Users */}
                <div className="mt-12 text-black">
                    <p>
                        Showing {startIndex + 1} to{" "}
                        {endIndex > filteredUsers.length ? filteredUsers.length : endIndex}{" "}
                        out of {filteredUsers.length} users
                    </p>
                </div>

                {/* Pagination Controls */}
                <div className="flex justify-center">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 text-white bg-[#0F1515] rounded-l"
                    >
                        Prev
                    </button>
                    <span className="px-4 py-2">{currentPage}</span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 text-white bg-[#0F1515] rounded-r"
                    >
                        Next
                    </button>
                </div>
            </div>

        </div>
    );
};

export default PaginatedTable;
