import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedTable from "../components/PaginatedTable";
import AdminNavbar from "../components/Navbar";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Dashboard = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(users.map((user, index) => ({
            No: index + 1,
            Name: user.name,
            Email: user.email,
            Phone: user.phone,
            Community: user.community,
            "Registered At": user.created_at,
            "Registration ID": user.regist_id
        })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Registration");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, "ihf_registration.xlsx");
    };

    // Fetch users from the /registration endpoint
    useEffect(() => {
        const authToken = localStorage.getItem("auth_token");

        // If no token is found, redirect to the login page
        if (!authToken) {
            navigate("/");
        } else {
            // Fetch users if logged in
            const fetchUsers = async () => {
                try {
                    const response = await fetch(`https://api.indonesiaharleyfest.com/get-registration`);
                    const data = await response.json();
                    setUsers(data.data);
                } catch (err) {
                    setError("Failed to load users");
                } finally {
                    setLoading(false);
                }
            };

            fetchUsers();
        }
    }, []);

    return (
        <div className="bg-[#26242B] min-h-screen">
            <AdminNavbar />

            <main className="p-6">
                <div className="flex flex-row justify-between items-center">
                    <h2 className="text-2xl font-bold text-white">Registered Users</h2>
                    <button
                        onClick={exportToExcel}
                        className="my-4 px-4 py-2 bg-[#F36900] text-white rounded hover:bg-orange-600"
                    >
                        Export to Excel
                    </button>
                </div>


                {/* Table */}
                {loading ? (
                    <p className="text-white">Loading...</p>
                ) : users.length === 0 ? (
                    <p className="text-white">There are no registered users at the moment.</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <div className="overflow-x-auto">
                        <PaginatedTable users={users}
                            onRowClick={(id) => navigate(`/user-details/${id}`)} />
                    </div>

                )}
            </main>
        </div>
    );
};

export default Dashboard;
