import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const UserDetails = () => {
    const { id } = useParams(); // Get the user ID from the URL
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await fetch(`https://api.indonesiaharleyfest.com/get-registration/${id}`);
                const data = await response.json();

                if (data.success) {
                    setUser(data.data);
                } else {
                    throw new Error("Oops! We couldn't find any user registered with this ID. Please double-check the ID and try again.");
                }
            } catch (err) {
                setError(err.message || "Failed to fetch user details");
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [id]);

    if (loading) return (
        <div className="h-screen bg-[#26242B] flex flex-col items-center justify-center">
            <p className="text-white text-3xl">Loading...</p>
        </div>
    );
    if (error) return (
        <div className="h-screen bg-[#26242B] flex flex-col items-center justify-center text-center p-4">
            <p className="text-white text-3xl">Sorry, we couldn't find any user registered with this data.</p>
            <p className="text-white text-3xl">Please double-check the ID and try again.</p>
        </div>
    );

    return (
        <div className="bg-[#26242B] min-h-screen text-white">
            <main className="p-6">
                <button
                    onClick={() => navigate(-1)}
                    className="text-orange-500 underline mb-4"
                >
                    ← Back
                </button>
                <h2 className="text-2xl font-bold mb-4 text-white">User Details</h2>
                <div className="bg-[#F8FAFA] shadow py-8 px-4 rounded-lg text-black grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <span className="font-light">REGISTRATION ID</span>
                        <p className="text-lg">{user.regist_id}</p>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-light">NAME</span>
                        <p className="text-lg">{user.name}</p>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-light">EMAIL</span>
                        <p className="text-lg">{user.email}</p>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-light">PHONE</span>
                        <p className="text-lg">{user.phone}</p>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-light">COMMUNITY</span>
                        <p className="text-lg">{user.community}</p>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-light">REGISTERED ON</span>
                        <p className="text-lg">{new Date(user.created_at).toLocaleString()}</p>
                    </div>
                </div>
            </main>

        </div>
    );
};

export default UserDetails;
