import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import AdminNavbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

const NewsletterPage = () => {
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Fetch emails from the /get-newsletter endpoint
    useEffect(() => {
        const authToken = localStorage.getItem("auth_token");

        if (!authToken) {
            navigate("/login");
        } else {
            const fetchEmails = async () => {
                try {
                    const response = await fetch(`https://api.indonesiaharleyfest.com/get-newsletter`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch data");
                    }
                    const data = await response.json();
                    setEmails(data.data || []);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchEmails();
        }

    }, []);

    // Export emails to Excel
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(emails.map((email, index) => ({
            No: index + 1,
            Email: email.email
        })));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Emails");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, "newsletter_emails.xlsx");
    };

    return (
        <div className="bg-[#26242B] min-h-screen">
            <AdminNavbar />

            <div className="p-6">
                <h2 className="text-2xl font-bold mb-4 text-white">Newsletter Subscribers</h2>

                {loading ? (
                    <p className="text-white">Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : emails.length === 0 ? (
                    <p className="text-white">No subscribers found.</p>
                ) : (
                    <div className="p-0">
                        {/* Export Button */}
                        <div className="flex justify-between items-center">
                            <p className="text-white">Total Registrations: <span className="font-bold">{emails.length}</span></p>
                            <button
                                onClick={exportToExcel}
                                className="my-4 px-4 py-2 bg-[#F36900] text-white rounded hover:bg-orange-600"
                            >
                                Export to Excel
                            </button>
                        </div>

                        {/* Email Table */}
                        <div className="bg-[#F8FAFA] py-8 px-4 rounded-lg text-black">
                            <table className="min-w-full table-auto border-collapse bg-white">
                                <thead className="border-b  text-gray-500">
                                    <tr>
                                        <th className="px-4 py-2 font-light text-gray-400 w-20">#</th>
                                        <th className="px-4 py-2 text-start font-light">EMAIL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emails.map((email, index) => (
                                        <tr key={index} className="border-b hover:bg-[#f06512] hover:text-white">
                                            <td className="p-4 text-center text-gray-400">{index + 1}</td>
                                            <td className="px-4">{email.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                )}
            </div>

        </div>
    );
};

export default NewsletterPage;
