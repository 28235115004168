import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const response = await fetch(`https://api.indonesiaharleyfest.com/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const result = await response.json();

            if (response.ok) {
                setMessage('Login successful!');
                localStorage.setItem("auth_token", result.token);
                navigate('/dashboard');
            } else {
                setMessage(result.error || 'Login failed. Please try again.');
            }
        } catch (error) {
            setMessage('An error occurred during login.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="h-screen mx-auto p-4 bg-[#26242B] text-black flex flex-col items-center justify-center">
            <h2 className="text-2xl font-bold mb-8 md:w-1/2 mx-auto text-white text-center">IHF Admin Panel</h2>
            <form onSubmit={handleSubmit} className="md:w-1/2 mx-auto bg-white p-8 rounded-lg shadow">
                <div>
                    <label htmlFor="username" className="block text-xs font-medium text-gray-500">USERNAME</label>
                    <input
                        type="username"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md mb-4"
                        placeholder="Enter your username"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password" className="block text-xs font-medium text-gray-500">PASSWORD</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Enter your password"
                        required
                    />
                </div>
                {message && (
                    <p className={`mt-2 text-sm ${message.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                        {message}
                    </p>
                )}
                <button
                    type="submit"
                    className="w-full mt-8 py-2 text-white rounded-md bg-[#F36900] hover:bg-orange-600 font-bold"
                    disabled={loading}
                >
                    {loading ? 'Logging in...' : 'LOGIN'}
                </button>
            </form>
        </div>
    );
};

export default LoginPage;
